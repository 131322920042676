import './App.css';
import Footer from './components/Footer';
import Header from './components/Headers';
import desktopImage from './images/desktop.jpg';
import mobileImage from './images/mobile.jpg';
function App() {
  return (
    <div>
      <Header />
      <div className="banner-container">
        <img src={desktopImage} className="desktop-image" alt='desktop-landing-page' />
        <img src={mobileImage} className="mobile-image" alt='mobile-landing-page' />
      </div>
      <Footer />
    </div>
  );
}

export default App;
